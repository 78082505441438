// app/javascript/controllers/removals_controller.js

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["locationSelect"]

  change(event) {
    console.log("Hello, customer controller!")
    let customer_id = event.target.selectedOptions[0].value
    let target = this.locationSelectTarget.id
    get(`/contacts/locations?target=${target}&customer_id=${customer_id}`, {
      responseKind: "turbo-stream"
    })
  }
}
