import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="contacts"
export default class extends Controller {
  connect() {
    console.log("expertises controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->expertises#loadExpertises")
    console.log("expertises controller initialized")
  }

  loadExpertises(event) {
    let tag_id = event.target.selectedOptions[0].value
    console.log(tag_id)
    get(`/candidate_expertises/fetch_expertises?tag_id=${tag_id}`, {
      responseKind: "turbo-stream"
    })
  }

}
