import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="contacts"
export default class extends Controller {
  connect() {
    console.log("documents controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->document#loadAccounttypeCustomers")
    console.log("documents controller initialized")
  }

  loadAccounttypeCustomers(event) {
    // console.log("documents controller event")
    let accounttype_id = event.target.selectedOptions[0].value
    console.log(accounttype_id)
    get(`/documents/fetch_customers?accounttype_id=${accounttype_id}`, {
      responseKind: "turbo-stream"
    })
  }

}
