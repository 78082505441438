import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="contacts"
export default class extends Controller {
  connect() {
    console.log("relevance controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->relevance#loadContentcategories")
    console.log("relevance controller initialized")
  }

  loadContentcategories(event) {
    // console.log("documents controller event")
    let relevancecategory_id = event.target.selectedOptions[0].value
    console.log(relevancecategory_id)
    get(`/candidatedocuments/fetch_contentcategories?relevancecategory_id=${relevancecategory_id}`, {
      responseKind: "turbo-stream"
    })
  }
}
