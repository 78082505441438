// app/javascript/controllers/removals_controller.js

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {

  connect() {
    console.log("Hello, Stimulus!")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->jobapp#loadCustomerTasks")
  }

  loadCustomerTasks(event) {
    let customer_id = event.target.selectedOptions[0].value
    console.log(customer_id)
    get(`/jobapps/fetch_tasks?customer_id=${customer_id}`, {
      responseKind: "turbo-stream"
    })
  }
}
