import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="contacts"
export default class extends Controller {
  connect() {
    console.log("transaction controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->transaction#loadTransactions")
    console.log("transaction controller initialized")
  }

  loadTransactions(event) {
    // console.log("documents controller event")
    let customer_id = event.target.selectedOptions[0].value
    console.log(customer_id)
    get(`/documents/fetch_transactions?customer_id=${customer_id}`, {
      responseKind: "turbo-stream"
    })
  }

}
