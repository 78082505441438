// app/javascript/controllers/removals_controller.js

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
static targets = ["customerSelect", "transactionSelect", "contentcategorySelect"]

  change(event) {
   let accounttype_id = event.target.selectedOptions[0].value 
   let target = this.customerSelectTarget.id

   get (`/documents/customers?target=${target}&accounttype_id=${accounttype_id}`,{
     responseKind: "turbo-stream"
   })
  }

  change_customer(event) {
    let customer_id = event.target.selectedOptions[0].value 
    let target = this.transactionSelectTarget.id
 
    get (`/documents/transactions?target=${target}&customer_id=${customer_id}`,{
      responseKind: "turbo-stream"
    })
   }

   change_relevancecategory(event) {
    let relevancecategory_id = event.target.selectedOptions[0].value 
    let target = this.contentcategorySelectTarget.id
 
    get (`/documents/contentcategories?target=${target}&relevancecategory_id=${relevancecategory_id}`,{
      responseKind: "turbo-stream"
    })
   }
}
