import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="receipts"
export default class extends Controller {
  connect() {
    console.log("receipts controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->receipts#loadCustomerLocations")
    console.log("receipts controller initialized")
  }

  loadCustomerLocations(event) {
    let customer_id = event.target.selectedOptions[0].value
    console.log(customer_id)
    get(`/receipts/fetch_locations?customer_id=${customer_id}`, {
      responseKind: "turbo-stream"
    })
  }

}
