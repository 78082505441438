// app/javascript/controllers/removals_controller.js

import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  connect() {
    console.log("creditnote controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->creditnote#loadCustomerReceipts")
    console.log("contacts controller initialized")
  }

  loadCustomerReceipts(event) {
    let customer_id = event.target.selectedOptions[0].value
    console.log(customer_id)
    get(`/creditnotes/fetch_receipts?customer_id=${customer_id}`, {
      responseKind: "turbo-stream"
    })
  }
}
