import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="contacts"
export default class extends Controller {
  connect() {
    console.log("tags controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->tags#loadFunctionTags")
    console.log("tags controller initialized")
  }

  loadFunctionTags(event) {
    let function_id = event.target.selectedOptions[0].value
    console.log(function_id)
    get(`/candidates/fetch_tags?function_id=${function_id}`, {
      responseKind: "turbo-stream"
    })
  }

}
