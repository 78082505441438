import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

// Connects to data-controller="contacts"
export default class extends Controller {
  connect() {
    console.log("positioncategories controller connected")
  }

  initialize() {
    this.element.setAttribute("data-action", "change->positioncategories#loadFunctionalareaPositioncategories")
    console.log("positioncategories controller initialized")
  }

  loadFunctionalareaPositioncategories(event) {
    let functionalarea_id = event.target.selectedOptions[0].value
    console.log(functionalarea_id)
    get(`/candidates/fetch_positioncategories?functionalarea_id=${functionalarea_id}`, {
      responseKind: "turbo-stream"
    })
  }

}
